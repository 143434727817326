.card-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  .video {
    video {
      width: 100%;
    }
  }
  .capta-price--mobile {
    position: relative;
    display: none;
  }

  /* Mobile Capta */
  @media screen and (max-width: 767px) {
    .capta-price--mobile {
      padding: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0 4px;
      align-items: baseline;
      min-height: 2.5rem;
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  flex: auto;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  width: inherit;
  // background: black linear-gradient(360deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;

  &-container {
    z-index: 1;
  }
  &-container .card-container--info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: calc(100% - 130px);
  }

  &-container .card-container--image--stamp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap:0.5rem;
  }

  &-container .card-container--stamp {
    display: flex;
    width: 100%;
  }
  &-container .card-container-custom--info {
    gap: 5rem;
  }

  &-clickable {
    z-index: 2;
  }

  &-header {
    align-items: flex-start;
  }

  // @media (hover: hover) and (pointer: fine) {
  //   &-backgroundImg {
  //     z-index: 0;
  //     transition: 0.5s ease all;
  //   }
  // }

  &-img--opacity {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: -webkit-linear-gradient(to bottom, #00000000, #000000cb);
    background: -moz-linear-gradient(to bottom, #00000000, #000000cb);
    background: -ms-linear-gradient(to bottom, #00000000, #000000cb);
    background: -o-linear-gradient(to bottom, #00000000, #000000cb);
    background: linear-gradient(to bottom, #00000000, #000000cb);
  }

  &-shadow--10 {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0 10px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  }

  &-shadow--1 {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0 1px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px;
  }

  &.layout-capta {
    justify-content: flex-end;
    padding: 5%;

    .card-container {
      &--image-three {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .card-header {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &--pretitle {
        display: none;
      }
      &--subtitle {
        display: none;
      }
    }
    .card-content {
      &--price {
        display: none;
      }
    }
  }
  &.layout-tariff {
    justify-content: flex-end;
    gap: 1rem;
    padding: 1.3rem;
    height: inherit;
    .card-container {
      height: inherit;
      &--info {
        justify-content: space-between;
        height: inherit;
      }
    }
    .card-header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &--title {
        line-height: 1.2;
      }
      &--icon {
        position: absolute;
        top: 0;
        padding-top: 1.5rem;
      }
      &--icon_support {
        min-height: 56px;
      }
    }
    .card-content--price {
      &_small {
      }
      &_big {
        display: none;
      }
    }
  }

  &.layout-tradeIn {
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 1.3rem;
    height: inherit;
    .card-container {
      height: inherit;
      &--info {
        justify-content: space-between;
        height: inherit;
      }
    }
    .card-header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &--title {
        line-height: 1.2;
      }
    }
  }

  &.layout-product {
    padding: 1.5rem;
    border: 1px solid #ddd;
    .card-container {
      display: flex;
      gap: 1rem;
      flex-direction: row-reverse;
      justify-content: space-between;

      &--image {
        aspect-ratio: 240/296;
        position: relative;
        margin: 0 auto;
        max-width: 7rem;
        width: 100%;
        .custom-image {
          border-radius: 16px;
        }
      }
      .card-content--price-block {
        display: flex;
      }
      .card-header {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      &--label {
        min-height: 2rem;
      }
      &--subtitle {
        max-width: 17rem;
      }
    }
  }
  &.layout-dispo-product {
    border: 1px solid #ddd;
    .card-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      column-gap: 1rem;
      &--image {
        aspect-ratio: 240/296;
        position: relative;
        margin: 0 auto;
        width: 100%;
      }
      .card-header {
        line-height: 20px;
      }
      .card-content--stamp-text{
        margin: 0.5rem 0;
      }
      .card-content--price .price {
        margin-top: 0.3em;
      }

      .card-content--price .price--post-price {
        margin-top: 0.1rem;
      }

      .card-content--price .price--separator {   
        border:0;     
        border-bottom: 1px solid rgb(221, 221, 221);
        margin: 0.25rem 0px;
      }
      .card-content--price .price--libre-price {   
       display: flex;
       gap:0.5rem;
      }
      .card-content--price_amount {
        display: flex;
        gap: 4px;
      }
    }
  }

  &.line-breaker {
    border: #fff solid 0.5px;
    margin: 1rem 0;
  }

  /* Mobile S */
  @media screen and (max-width: 375px) {
    &.layout-capta {
      .card-header {
        line-height: 20px;
      }
    }
  }
  /* Mobile M */
  @media screen and (min-width: 426px) {
    &.layout-capta {
      .card-header {
        line-height: 26px;
      }
    }
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    flex: 1;
    &.layout-capta {
      .card-header {
        line-height: 29px;
        &--pretitle {
          display: block;
        }

        &--subtitle {
          display: block;
        }
      }
      .card-content {
        &--price {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          align-items: baseline;
        }
      }
    }
    &.layout-tariff {
      .card-header {
        &--icon {
          display: block;
        }
      }
      .card-container {
        &--info {
          gap: 2rem;
        }
      }
      .card-content {
        .card-container--label-two {
          margin-bottom: 0.3rem;
        }
      }

      .card-content--price {
        &_small {
          display: none;
        }
        &_big {
          display: block;
        }
      }
    }
    &.layout-product {
      padding: 1.5rem;
      .card-header {
        &--title {
          margin-top: 0.3rem;
        }
        &--subtitle {
          margin-top: 0.3rem;
        }
      }
      .card-container {
        flex-direction: column;
        align-items: flex-start;
        &--image {
          max-width: 18rem;
          width: 100%;
        }
        &--subtitle {
          max-width: none;
        }
      }
    }
    &.layout-dispo-product {
      .card-container {
        .card-header {
          &--title {
            -webkit-line-clamp: 3;
          }
        }
        &--countDown {
          min-height: 2rem;
          width: 100%;
        }
        &--image {
          aspect-ratio: 240/296;
          position: relative;
          margin: 0 auto;
          width: 100%;
        }
        &--info {
          flex: 1;
          line-height: 24px;
        }
      }
    }

    .post-card-mobile {
      display: none;
    }
  }
  /* Tablet para cambiar tamaños texto solo */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    &.layout-capta {
      .card-header {
        line-height: 26px;
      }
    }
    &.layout-tariff {
      .card-container {
        &--info {
          gap: 1rem;
        }
      }
    }
  }
  /* Desktop */
  @media screen and (min-width: 1024px) {
    border-radius: 25px;
    &.layout-dispo-product {
      .card-container {
        &--image {
          aspect-ratio: 240/296;
          position: relative;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
    &.layout-capta {
      .card-header--title {
        line-height: 40px;
      }
    }
    &.layout-product {
      .card-container {
        &--image {
          padding-top: 125%;
        }
      }
    }
  }

  @media screen and (min-width: 1280px) {
  }

  @media screen and (min-width: 1512px) {
    &.layout-tariff {
      .card-container {
        &--info {
          gap: 4rem;
        }
      }
    }
    &.layout-dispo-product {
      .card-container {
        &--image {
          aspect-ratio: 240/296;
          position: relative;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }
}

// .layout-capta:hover img {
//   transform: scale(1.1);
// }

.banner-cards {
  .card-container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: space-between;
    .card-glyph {
      position: relative;
      max-height: 85px;
      .glyph-content {
        height: 100%;
        width: 100%;
      }
    }
  }
  .layout-tariff {
    padding: 5%;
  }

  .card-backgroundImg--desktop {
    display: none;
  }
  .card-backgroundImg--tablet {
    display: none;
  }
  .card-backgroundImg--mobile {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .banner-cards {
    .card-container {
      .card-glyph {
        max-height: 115px;
      }
    }
    .card-backgroundImg--tablet {
      display: block;
    }
    .card-backgroundImg--mobile {
      display: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  .banner-cards {
    .card-backgroundImg--desktop {
      display: block;
    }
    .card-backgroundImg--tablet {
      display: none;
    }
  }
}
// Ocultar price tariff cuando esta en banner
@media screen and (max-width: 768px) {
  .banner-cards {
    .layout-tariff {
      justify-content: flex-end;
      .post-card {
        .card-content {
          &--price {
            display: none;
          }
        }
      }
      .card-content {
        .card-container--label-two {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .featuredOfferCards {
    .layout-tariff {
      .card-content {
        .card-container--label-two {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
