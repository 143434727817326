
  @font-face { 
    font-family: "Regular";
    src: url("https://prod.cms.col.movistar.es/static/fonts/gp/Regular.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/gp/Regular.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face { 
    font-family: "Thin";
    src: url("https://prod.cms.col.movistar.es/static/fonts/gp/Thin.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/gp/Thin.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face { 
    font-family: "Light";
    src: url("https://prod.cms.col.movistar.es/static/fonts/gp/Light.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/gp/Light.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face { 
    font-family: "Bold";
    src: url("https://prod.cms.col.movistar.es/static/fonts/gp/Bold.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/gp/Bold.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
  }
  @font-face { 
    font-family: "RegularPlus";
    src: url("https://prod.cms.col.movistar.es/static/fonts/movistarPlus/apercu_movistar_regular-web.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/movistarPlus/apercu_movistar_regular-web.woff2") format("woff2");
    font-weight: 500;
    font-display: swap;
  }
  @font-face { 
    font-family: "BoldPlus";
    src: url("https://prod.cms.col.movistar.es/static/fonts/movistarPlus/apercu_movistar_bold-web.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/movistarPlus/apercu_movistar_bold-web.woff2") format("woff2");
    font-weight: 500;
    font-display: swap;
  }
  @font-face { 
    font-family: "LightPlus";
    src: url("https://prod.cms.col.movistar.es/static/fonts/movistarPlus/apercu_movistar_black-web.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/movistarPlus/apercu_movistar_black-web.woff2") format("woff2");
    font-weight: 500;
    font-display: swap;
  }
  @font-face { 
    font-family: "RegularEmp";
    src: url("https://prod.cms.col.movistar.es/static/fonts/emp/Regular.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/emp/Regular.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face { 
    font-family: "BoldEmp";
    src: url("https://prod.cms.col.movistar.es/static/fonts/emp/Bold.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/emp/Bold.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face { 
    font-family: "LightEmp";
    src: url("https://prod.cms.col.movistar.es/static/fonts/emp/Light.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/emp/Light.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }
  @font-face { 
    font-family: "ThinEmp";
    src: url("https://prod.cms.col.movistar.es/static/fonts/emp/Thin.woff") format("woff"), url("https://prod.cms.col.movistar.es/static/fonts/emp/Thin.woff2") format("woff2");
    font-weight: normal;
    font-display: swap;
  }