*,
::after,
::before {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  margin: 0;
  padding: 0;
}
* {
  line-height: 120%;
}
html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
}
main {
  display: block;
}
address,
blockquote,
dl,
figure,
form,
iframe,
p,
pre,
table {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}
pre {
  font-family: monospace, monospace;
  font-size: inherit;
}
address {
  font-style: inherit;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  text-decoration-color: none;
}
.text a{
  color: #0079BB
}
abbr[title] {
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bold;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
embed,
iframe,
img,
object,
svg {
  vertical-align: bottom;
}
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: 0 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
}

[type="checkbox"] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}
[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer;
}
[type="button"]:disabled,
[type="reset"]:disabled,
[type="submit"]:disabled,
button:disabled {
  cursor: default;
}
:-moz-focusring {
  outline: auto;
}
select:disabled {
  opacity: inherit;
}
option {
  padding: 0;
}
fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="text"]::-ms-clear {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
label[for] {
  cursor: pointer;
}
details {
  display: block;
}
summary {
  display: list-item;
}
[contenteditable]:focus {
  outline: auto;
}
table {
  border-color: inherit;
}
caption {
  text-align: left;
}
td,
th {
  vertical-align: top;
  padding: 0;
}
th {
  text-align: left;
  font-weight: 700;
}
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-weight: normal;
}
ul,
ol {
  list-style: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
img,
embed,
object,
video {
  display: block;
  height: auto;
  max-width: 100%;
}
iframe {
  display: block;
  max-width: 100%;
}
audio {
  max-width: 100%;
}
iframe {
  border: 0;
} 
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
  text-align: left;
}


a:active,
a:hover
a:visited,
input:active,
button:active {
  border: 0;
  outline: 1px solid transparent;
  text-decoration-color: transparent;
}

// outline con tab key
a:focus-visible,
input:focus-visible,
button:focus-visible {
  border: 0;
  outline: 1px solid black;
  outline-width: 1px;
  outline-style: auto;
}
.cms-block{position:relative}
.outlined {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 0.5s ease;
}

.outlined:hover {
  filter: brightness(70%);
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 0.5s ease;
}

/* CSS UNIFICADO COMUN PARA EL UNIVERSO */
body,
html {
  font-family: "Light", Arial, Helvetica;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  cursor: default;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.skeleton {
  width: 100%;
  height: 100%;
  z-index: 10;
  animation: skeleton-loading 1s linear infinite alternate;
  &.loaded {
    display: none;
    * {
      opacity: 1;
    }
  }
  &.noanimated {
    animation: none;
    * {
      opacity: 1;
    }
  }
  * {
    opacity: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


@mixin add-aspect-ratio($first-integer, $second-integer) {
  aspect-ratio: calc($first-integer / $second-integer);

  position: relative;
  width: 100%;

  &::before {
    display: block;
    content: "";
    top: 0;
    width: 100%;
    padding-top: percentage($second-integer / $first-integer);
  }

  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & source {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.aspect-ratio {
  // full hd
  &-9-16 {
    @include add-aspect-ratio(9, 16);
  }

  &-16-9 {
    @include add-aspect-ratio(16, 9);
  }

  //BASE 1
  &-1-1 {
    @include add-aspect-ratio(1, 1);
  }

  &-2-1 {
    @include add-aspect-ratio(2, 1);
  }

  // BASE 2
  &-3-2 {
    @include add-aspect-ratio(3, 2);
  }

  &-5-2 {
    @include add-aspect-ratio(5, 2);
  }

  // BASE 3
  &-2-3 {
    @include add-aspect-ratio(2, 3);
  }

  &-10-3 {
    @include add-aspect-ratio(10, 3);
  }

  // BASE 5
  &-4-5 {
    @include add-aspect-ratio(4, 5);
  }

  &-7-5 {
    @include add-aspect-ratio(7, 5);
  }
}



.svg-layout-size {
  position: relative;
  display: inline-block;
  overflow: hidden;
  svg {
    display: block;
    position: relative;
  }
}

.text-content{
  line-height: 120% !important  
}

.transparent {
  background-color: rgba(255, 255, 255, 0);
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0deg 0% 91.25%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@-webkit-keyframes rotating {
  from {
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.Spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  overflow: visible;

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }

  .path.circle {
    -webkit-animation: dash 0.9s linear;
    animation: dash 0.9s linear;
  }
  .path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s linear forwards;
    animation: dash 0.9s 0.35s linear forwards;
  }
  .path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s linear forwards;
    animation: dash-check 0.9s 0.35s linear forwards;
  }
}

.Spin.loading {
  animation: rotating 1s linear infinite;
}