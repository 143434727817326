.guide-list {
  display: flex;
  flex-direction: column;

  .separator {
    border-style: solid;
    border-top-width: 1px;
    color: #dddddd;
    margin-top: 1.5rem;
  }
  .guide-list--title {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .guide-list--faqs {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    .buttonx {
      padding: 0 2px;
    }
  }

  .guide-list--items {
    display: flex;
    justify-content: space-between;

    .guide-list--row {
      display: flex;
      margin-bottom: 4px;
      gap: 1rem;
      width: 100%;
      max-width: 24%;

      .guide-list--row--text {
        margin-bottom: 1rem;
        h3 {
          margin-bottom: 0.25rem;
        }
        :global(a) {
          color: #0079bb;
        }
        :global(a:hover) {
          color: #80cef9;
        }
      }

      .guide-list--row--icon {
        display: flex;
        align-items: stretch;
        position: relative;

        .guide-list--row--iconRight {
          background: #008edd 0% 0% no-repeat padding-box;
          display: flex;
          height: 32px;
          width: 32px;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          z-index: 1;
          p {
            color: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .guide-list--title {
      margin-bottom: 1rem;
    }
    .guide-list--faqs {
      margin-top: 0;
    }
    .guide-list--items {
      flex-direction: column;
      .guide-list--row {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 425px) {
    .guide-list--faqs {
      flex-direction: column;
      align-items: flex-start;
      .buttonx { 
        padding: 0
      }
    }
  }
}
