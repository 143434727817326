.teletipo-skeleton {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.skeleton-offers-title {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.skeleton-banner-card {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  &.postcard {
    height: 80%;
    top: 5px;
  }
}

.skeleton-superbanner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 16px;

  & > .skeleton-superbanner-cont {
    height: 100%;
  }

  & > .superbanner-post-card {
    border-radius: 16px;
    z-index: 20;
    background-color: red !important;
    display: block;
  }
}

.skeleton-device-offers-card {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.skeleton-ecosystem {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.skeleton-buybox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-radius: 8px;
  height: 100%;

  &.skeleton-label {
    position: relative;
    height: 1.75rem;
    width: 100%;
  }
  &.skeleton-price {
    position: relative;
    height: 1.75rem;
    width: 100%;
  }
  &.skeleton-text {
    position: relative;
    min-height: 0.85rem;
    width: 100%;
  }
  &.skeleton-button {
    position: relative;
    height: 3rem;
    width: auto;
    display: flex;
    justify-content: flex-start;
  }
  &.skeleton-legals {
    position: relative;
    min-height: 1.5rem;
    width: 100%;

    &.with-pre-legals {
      min-height: 4.5rem;

      @media screen and (min-width: 425px) {
        min-height: 4rem;
      }
    }
  }
}
.skeleton-sticky-button {
  position: relative;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
