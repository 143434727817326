.section-device-slider {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  width: 100%;
  & .column-images {
    display: none;
  }
  & .device-slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    &--label {
      height: 2rem;
    }
    & .device-slider {
      display: flex;
      transition: transform 0.3s ease-in-out;
      height: 17.5rem;
      &--slide {
        position: relative;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        & .moving {
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
  & .device-slider-counter {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 25;
  }
}

/* Desktop */
@media screen and (min-width: 768px) {
  .section-device-slider {
    height: 31rem;
    width: auto;
    & .column-images {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 2rem;
      width: 18rem;
      &--container {
        display: flex;
        justify-content: center;
        width: 60%;
        height: 20%;
        padding: 0.25rem;
        .custom-link-image-slider {
          width: 100%;
        }
        .custom-link-image-slider:focus {
          outline: none;
        }
        &--image-box {
          position: relative;
          height: 100%;
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
        &--active {
          border-radius: 0.25rem;
          border: 1px solid #019df4;
        }
      }
    }
    & .device-slider-container {
      & .device-slider {
        height: 27rem;
        & img {
          height: 27rem;
        }
      }
    }
    & .device-slider-counter {
      visibility: hidden;
    }
  }
}