.alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
  
    .buttonx {
      padding: 0 2px;
      background-color: transparent !important;
      border-color: transparent !important;
      text-align: left;
      justify-content: left;
    }
    &.justify-content-center {
      justify-content: center;
    }
    .alert-text {
      display: flex;
      align-items: baseline;
    }
  
    .icon-user {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      background-color: white;
      border-radius: 50%;
      margin-right: 0.625rem;
    }
    @media screen and (max-width: 1024px) {
    padding: 8px 16px;
  
      .alert-text {
        flex-direction: column;
      }
    }
  }