.product-modal {
    width: 428px;
    height: auto;
    background: white;
    min-height: 100vh;

    @media screen and (max-width: 428px) {
        width: 100vw;
    }
}

.product-modal__close {
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    width: 2rem;
    height: 2rem;
    padding: 0.625rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.5rem;
    cursor: pointer;
    z-index: 2;
}

.product-modal__header {
    position: relative;
    overflow: hidden !important;
    padding: 9rem 1.5rem 1.5rem;

    @media screen and (max-width: 428px) {
        padding: 9rem 2.5rem 1.5rem;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            background-image: linear-gradient(180deg,
                    rgba(11, 39, 57, 0) 0%,
                    #0b2739 90%);
            z-index: 2;
        }
    }

    .caption {
        position: relative;
        z-index: 2;

        .kicker {
            line-height: 1.5rem;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }

        .title {
            line-height: 2.5rem;
            margin-bottom: 1rem;
        }

        .description {
            line-height: 1.5rem;
        }
    }
}

.product-modal__content {
    .product-modal__price {
        display: flex;
        gap: 0.5rem;
        padding: 1.5rem;
    }

    .features {
        &__section {
            padding: 1.5rem;
            background-color: #f2f2f2;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        &__list {
            margin: 1rem 0 2rem;

            &-title {
                padding: 0.5rem 1.5rem 0rem 1.5rem;
            }

            ul {
                padding: 0.5rem 2.5rem;
                list-style-type: inherit !important;
            }

            li {
                padding: 0.5rem 0;
            }
        }

        &__text {
            margin-top: 1rem;
        }
    }

    .FeatureList-action {
        margin-left: 1.5rem;
    }

    .FeatureList ul {
        padding: 0 2.5rem;
        list-style-type: inherit !important;
    }

    .FeatureList ul li {
        padding: 0.5rem 0;
    }

    hr {
        border-top: 1px solid #f1f1f1;
    }

    .included {
        padding: 1.5rem;

        .text-info.heading {
            margin-bottom: 1.5rem;
        }

        &__list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 2rem;
            grid-column-gap: 1.5rem;
            padding: 1rem 0;
        }

        &__item {
            text-align: center;

            .icon {
                margin-bottom: 0.75rem;
            }

            .text-info {
                padding-top: 0.75rem;
            }
        }
    }

    .ChannelList {
        background-color: #F5F5F5;
        padding: 2rem 1.5rem;

        &__title {
            margin-bottom: 2rem;
        }
        &__content {
            .arrow-container {
                top: 38%;
            }

            .carousel-window {
                overflow-x: scroll;
            }

            .ChannelList__img {
                width: 5rem;
                height: 5rem;
                padding: 0.8rem;
                border: 1px solid #DDD;
                box-shadow: 0 0 5px 0 #ededed;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
            }
        }
    }

    .legals {
        padding: 3.2rem 1.5rem;
        background-color: #f2f2f2;

        .legals_title {
            margin-bottom: .5rem;
        }

        .legal__item {
            margin-top: 1rem;
        }
    }

    .CharacList {
        padding-bottom: 2rem;

        .CharacList__title {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
        }

        &__item {
            width: 100%;
            text-align: center;
        }

        .Charac {
            padding: 0 0.3rem;

            .Charac_iconLabel {
                margin-bottom: 0.5rem;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .CharacList {
            &__item {
                width: 45%;
                max-width: 17rem;

                :global(.svg-layout-size) {
                    margin-bottom: 1rem;
                }
            }

            &__container {
                padding: 0 1rem;
            }

            &__list {
                justify-content: center;
                gap: 1.5rem 1rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .product-modal {
            .image-desktop {
                display: none;
            }

            .image-mobile {
                display: block;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .product-modal {
            .image-desktop {
                display: block;
            }

            .image-mobile {
                display: none;
            }
        }
    }
}