html {
  scroll-behavior: smooth;
}
.anchor {
  height: 38px;
}
.tv-card-container {
  position: relative;
  margin-bottom: 1.5rem;

  .tv-card-section-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .button-configura-desk {
      padding: 0;
      display: flex;
      margin-top: 1.2rem;
    }
  }

  .button-configura-mobile {
    display: none;
  }

  .tv-card-container-carousel {
    position: relative;
    .carousel {
      position: relative;
      .carousel-window {
        position: relative;
        gap: 24px;

        .carousel-item-wrapper {
          width: min-content;
        }
      }
    }

    .tv-card-content {
      position: relative;
      display: inline-flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .tv-card-container-grid {
    position: relative;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tv-card {
  border-radius: 16px;
  border: 1px solid #eeeeee;
  opacity: 1;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  min-width: 348px;
  height: 100%;
  overflow: hidden;
  position: relative;

  .overContent {
    z-index: 1;

    &:focus {
      border-radius: 16px;
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #dddddd;
  }

  .tv-card-img {
    position: relative;
    text-align: center;
    color: white;

    &.without-image {
      margin-bottom: 22px;
    }

    picture {
      img {
        border-radius: 0;
      }
    }
  }

  .tv-card-recommend {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0b2739;
    border-radius: 1rem 0;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1.6px 1rem;

    .text {
      line-height: inherit;
    }
  }

  .tv-card-empty-recommend {
    background-color: #0b2739;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 16px 16px 0px 0px;
  }

  .card-content-container {
    padding: 0 1rem;
    padding-bottom: 1rem;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    // min-height: 248px;

    &.simple-card-content {
      padding-top: 1rem;
    }

    .tv-card-text {
      display: flex;
      align-items: center;
      gap: 11px;
      margin: 0.3rem 0;
      max-height: 36px;

      .tv-card-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #313235;
      }

      .tv-card-image {
        height: 60px;
        position: relative;
        min-width: 75px;
        padding: 0;
      }

      .tv-card-title {
        max-width: 14rem;
      }
    }

    .tv-card-desc {
      flex-wrap: wrap;
      width: auto;

      .text {
        line-height: 1.5rem;
      }
    }

    .promo-label {
      margin-top: 0.5rem;
    }

    .claim-promo {
      padding-top: 0.4rem;
    }

    .price-container {
      display: flex;
      gap: 0.75rem;
      padding: 1.5rem 0;
      &.with-promo {
        padding: 0.2rem 0;
      }
    }

    .tv-card-buy-button {
      margin-top: 0.3rem;
    }

    .tv-card-promo {
      margin-left: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 50px;
      height: 22px;
      align-content: center;
      background: #b22634 0% 0% no-repeat padding-box;
    }

    .tv-card-promo-empty {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 50px;
      height: 22px;
      align-content: center;
      background: white;
    }

    .tv-card-price-text-desc {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding-left: 16px;
      padding-right: 16px;

      span {
        text-decoration: line-through #86888c;
      }
    }

    .tv-card-buy-button {
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      .detail-button {
        justify-content: flex-start;
        padding: 0;
        margin-top: 0.5rem;
        height: 1rem;
      }

      a {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 1536px) {
  .tv-card {
    min-width: 316px;
  }
}

@media screen and (max-width: 1240px) {
  .anchor {
    height: 25px;
  }
}

@media screen and (max-width: 768px) {
  .anchor {
    height: 45px;
  }
  .tv-card-container {
    margin-top: 0.75rem;

    .tv-card-section-title {
      .button-configura-desk {
        display: none;
      }
    }
    .container-button-configura-mobile {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      .button-configura-mobile {
        display: inline-flex;
      }
    }
    .tv-card-container-carousel {
      .carousel {
        .carousel-window {
          gap: 12px;
        }
      }
    }
    .tv-card-container-grid {
      display: flex;
      gap: 24px;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
  .tv-card {
    width: 100%;
    height: auto;

    .card-content-container {
      .tv-card-buy-button {
        flex-direction: row;
      }
    }
  }
}
