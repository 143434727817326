$color-gp--blue_movistar: #019DF4;
$color-gp--blue_movistar_o10: #019DF41A;
$color-gp--blue_movistar_o20: #019DF433;
$color-gp--blue_movistar_o30: #019DF44D;
$color-gp--blue_movistar_o40: #019DF466;
$color-gp--blue_movistar_o50: #019DF480;
$color-gp--blue_movistar_o60: #019DF499;
$color-gp--blue_movistar_o70: #019DF4B3;
$color-gp--blue_movistar_o80: #019DF4CC;
$color-gp--blue_movistar_o90: #019DF4E6;
$color-gp--blue_movistar_o100: #019DF4FF;
$color-gp--blue_movistar_o00: #019DF400;
$color-gp--blue: #0079BB;
$color-gp--blue_o10: #0079BB1A;
$color-gp--blue_o20: #0079BB33;
$color-gp--blue_o30: #0079BB4D;
$color-gp--blue_o40: #0079BB66;
$color-gp--blue_o50: #0079BB80;
$color-gp--blue_o60: #0079BB99;
$color-gp--blue_o70: #0079BBB3;
$color-gp--blue_o80: #0079BBCC;
$color-gp--blue_o90: #0079BBE6;
$color-gp--blue_o100: #0079BBFF;
$color-gp--blue_o00: #0079BB00;
$color-gp--blue_a: #066FCB;
$color-gp--blue_a_o10: #066FCB1A;
$color-gp--blue_a_o20: #066FCB33;
$color-gp--blue_a_o30: #066FCB4D;
$color-gp--blue_a_o40: #066FCB66;
$color-gp--blue_a_o50: #066FCB80;
$color-gp--blue_a_o60: #066FCB99;
$color-gp--blue_a_o70: #066FCBB3;
$color-gp--blue_a_o80: #066FCBCC;
$color-gp--blue_a_o90: #066FCBE6;
$color-gp--blue_a_o100: #066FCBFF;
$color-gp--blue_a_o00: #066FCB00;
$color-gp--blue_a_1: #055EAC;
$color-gp--blue_a_1_o10: #055EAC1A;
$color-gp--blue_a_1_o20: #055EAC33;
$color-gp--blue_a_1_o30: #055EAC4D;
$color-gp--blue_a_1_o40: #055EAC66;
$color-gp--blue_a_1_o50: #055EAC80;
$color-gp--blue_a_1_o60: #055EAC99;
$color-gp--blue_a_1_o70: #055EACB3;
$color-gp--blue_a_1_o80: #055EACCC;
$color-gp--blue_a_1_o90: #055EACE6;
$color-gp--blue_a_1_o100: #055EACFF;
$color-gp--blue_a_1_o00: #055EAC00;
$color-gp--blue_b: #414861;
$color-gp--blue_b_o10: #4148611A;
$color-gp--blue_b_o20: #41486133;
$color-gp--blue_b_o30: #4148614D;
$color-gp--blue_b_o40: #41486166;
$color-gp--blue_b_o50: #41486180;
$color-gp--blue_b_o60: #41486199;
$color-gp--blue_b_o70: #414861B3;
$color-gp--blue_b_o80: #414861CC;
$color-gp--blue_b_o90: #414861E6;
$color-gp--blue_b_o100: #414861FF;
$color-gp--blue_b_o00: #41486100;
$color-gp--blue_bf: #009ff7;
$color-gp--blue_bf_o10: #009ff71A;
$color-gp--blue_bf_o20: #009ff733;
$color-gp--blue_bf_o30: #009ff74D;
$color-gp--blue_bf_o40: #009ff766;
$color-gp--blue_bf_o50: #009ff780;
$color-gp--blue_bf_o60: #009ff799;
$color-gp--blue_bf_o70: #009ff7B3;
$color-gp--blue_bf_o80: #009ff7CC;
$color-gp--blue_bf_o90: #009ff7E6;
$color-gp--blue_bf_o100: #009ff7FF;
$color-gp--blue_bf_o00: #009ff700;
$color-gp--blue_dark: #0B2739;
$color-gp--blue_dark_o10: #0B27391A;
$color-gp--blue_dark_o20: #0B273933;
$color-gp--blue_dark_o30: #0B27394D;
$color-gp--blue_dark_o40: #0B273966;
$color-gp--blue_dark_o50: #0B273980;
$color-gp--blue_dark_o60: #0B273999;
$color-gp--blue_dark_o70: #0B2739B3;
$color-gp--blue_dark_o80: #0B2739CC;
$color-gp--blue_dark_o90: #0B2739E6;
$color-gp--blue_dark_o100: #0B2739FF;
$color-gp--blue_dark_o00: #0B273900;
$color-gp--blue_telefonica: #0066FF;
$color-gp--blue_telefonica_o10: #0066FF1A;
$color-gp--blue_telefonica_o20: #0066FF33;
$color-gp--blue_telefonica_o30: #0066FF4D;
$color-gp--blue_telefonica_o40: #0066FF66;
$color-gp--blue_telefonica_o50: #0066FF80;
$color-gp--blue_telefonica_o60: #0066FF99;
$color-gp--blue_telefonica_o70: #0066FFB3;
$color-gp--blue_telefonica_o80: #0066FFCC;
$color-gp--blue_telefonica_o90: #0066FFE6;
$color-gp--blue_telefonica_o100: #0066FFFF;
$color-gp--blue_telefonica_o00: #0066FF00;
$color-gp--blue_orion: #4e73df;
$color-gp--blue_orion_o10: #4e73df1A;
$color-gp--blue_orion_o20: #4e73df33;
$color-gp--blue_orion_o30: #4e73df4D;
$color-gp--blue_orion_o40: #4e73df66;
$color-gp--blue_orion_o50: #4e73df80;
$color-gp--blue_orion_o60: #4e73df99;
$color-gp--blue_orion_o70: #4e73dfB3;
$color-gp--blue_orion_o80: #4e73dfCC;
$color-gp--blue_orion_o90: #4e73dfE6;
$color-gp--blue_orion_o100: #4e73dfFF;
$color-gp--blue_orion_o00: #4e73df00;
$color-gp--white: #ffffff;
$color-gp--white_o10: #ffffff1A;
$color-gp--white_o20: #ffffff33;
$color-gp--white_o30: #ffffff4D;
$color-gp--white_o40: #ffffff66;
$color-gp--white_o50: #ffffff80;
$color-gp--white_o60: #ffffff99;
$color-gp--white_o70: #ffffffB3;
$color-gp--white_o80: #ffffffCC;
$color-gp--white_o90: #ffffffE6;
$color-gp--white_o100: #ffffffFF;
$color-gp--white_o00: #ffffff00;
$color-gp--black: #000000;
$color-gp--black_o10: #0000001A;
$color-gp--black_o20: #00000033;
$color-gp--black_o30: #0000004D;
$color-gp--black_o40: #00000066;
$color-gp--black_o50: #00000080;
$color-gp--black_o60: #00000099;
$color-gp--black_o70: #000000B3;
$color-gp--black_o80: #000000CC;
$color-gp--black_o90: #000000E6;
$color-gp--black_o100: #000000FF;
$color-gp--black_o00: #00000000;
$color-gp--grey: #2A2A3C;
$color-gp--grey_o10: #2A2A3C1A;
$color-gp--grey_o20: #2A2A3C33;
$color-gp--grey_o30: #2A2A3C4D;
$color-gp--grey_o40: #2A2A3C66;
$color-gp--grey_o50: #2A2A3C80;
$color-gp--grey_o60: #2A2A3C99;
$color-gp--grey_o70: #2A2A3CB3;
$color-gp--grey_o80: #2A2A3CCC;
$color-gp--grey_o90: #2A2A3CE6;
$color-gp--grey_o100: #2A2A3CFF;
$color-gp--grey_o00: #2A2A3C00;
$color-gp--grey1: #F5F5F5;
$color-gp--grey1_o10: #F5F5F51A;
$color-gp--grey1_o20: #F5F5F533;
$color-gp--grey1_o30: #F5F5F54D;
$color-gp--grey1_o40: #F5F5F566;
$color-gp--grey1_o50: #F5F5F580;
$color-gp--grey1_o60: #F5F5F599;
$color-gp--grey1_o70: #F5F5F5B3;
$color-gp--grey1_o80: #F5F5F5CC;
$color-gp--grey1_o90: #F5F5F5E6;
$color-gp--grey1_o100: #F5F5F5FF;
$color-gp--grey1_o00: #F5F5F500;
$color-gp--grey2: #EEEEEE;
$color-gp--grey2_o10: #EEEEEE1A;
$color-gp--grey2_o20: #EEEEEE33;
$color-gp--grey2_o30: #EEEEEE4D;
$color-gp--grey2_o40: #EEEEEE66;
$color-gp--grey2_o50: #EEEEEE80;
$color-gp--grey2_o60: #EEEEEE99;
$color-gp--grey2_o70: #EEEEEEB3;
$color-gp--grey2_o80: #EEEEEECC;
$color-gp--grey2_o90: #EEEEEEE6;
$color-gp--grey2_o100: #EEEEEEFF;
$color-gp--grey2_o00: #EEEEEE00;
$color-gp--grey3: #DDDDDD;
$color-gp--grey3_o10: #DDDDDD1A;
$color-gp--grey3_o20: #DDDDDD33;
$color-gp--grey3_o30: #DDDDDD4D;
$color-gp--grey3_o40: #DDDDDD66;
$color-gp--grey3_o50: #DDDDDD80;
$color-gp--grey3_o60: #DDDDDD99;
$color-gp--grey3_o70: #DDDDDDB3;
$color-gp--grey3_o80: #DDDDDDCC;
$color-gp--grey3_o90: #DDDDDDE6;
$color-gp--grey3_o100: #DDDDDDFF;
$color-gp--grey3_o00: #DDDDDD00;
$color-gp--grey4: #999999;
$color-gp--grey4_o10: #9999991A;
$color-gp--grey4_o20: #99999933;
$color-gp--grey4_o30: #9999994D;
$color-gp--grey4_o40: #99999966;
$color-gp--grey4_o50: #99999980;
$color-gp--grey4_o60: #99999999;
$color-gp--grey4_o70: #999999B3;
$color-gp--grey4_o80: #999999CC;
$color-gp--grey4_o90: #999999E6;
$color-gp--grey4_o100: #999999FF;
$color-gp--grey4_o00: #99999900;
$color-gp--grey5: #6B6C6F;
$color-gp--grey5_o10: #6B6C6F1A;
$color-gp--grey5_o20: #6B6C6F33;
$color-gp--grey5_o30: #6B6C6F4D;
$color-gp--grey5_o40: #6B6C6F66;
$color-gp--grey5_o50: #6B6C6F80;
$color-gp--grey5_o60: #6B6C6F99;
$color-gp--grey5_o70: #6B6C6FB3;
$color-gp--grey5_o80: #6B6C6FCC;
$color-gp--grey5_o90: #6B6C6FE6;
$color-gp--grey5_o100: #6B6C6FFF;
$color-gp--grey5_o00: #6B6C6F00;
$color-gp--grey6: #313235;
$color-gp--grey6_o10: #3132351A;
$color-gp--grey6_o20: #31323533;
$color-gp--grey6_o30: #3132354D;
$color-gp--grey6_o40: #31323566;
$color-gp--grey6_o50: #31323580;
$color-gp--grey6_o60: #31323599;
$color-gp--grey6_o70: #313235B3;
$color-gp--grey6_o80: #313235CC;
$color-gp--grey6_o90: #313235E6;
$color-gp--grey6_o100: #313235FF;
$color-gp--grey6_o00: #31323500;
$color-gp--grey_prosegur: #3A3C3F;
$color-gp--grey_prosegur_o10: #3A3C3F1A;
$color-gp--grey_prosegur_o20: #3A3C3F33;
$color-gp--grey_prosegur_o30: #3A3C3F4D;
$color-gp--grey_prosegur_o40: #3A3C3F66;
$color-gp--grey_prosegur_o50: #3A3C3F80;
$color-gp--grey_prosegur_o60: #3A3C3F99;
$color-gp--grey_prosegur_o70: #3A3C3FB3;
$color-gp--grey_prosegur_o80: #3A3C3FCC;
$color-gp--grey_prosegur_o90: #3A3C3FE6;
$color-gp--grey_prosegur_o100: #3A3C3FFF;
$color-gp--grey_prosegur_o00: #3A3C3F00;
$color-gp--purple: #A13EA1;
$color-gp--purple_o10: #A13EA11A;
$color-gp--purple_o20: #A13EA133;
$color-gp--purple_o30: #A13EA14D;
$color-gp--purple_o40: #A13EA166;
$color-gp--purple_o50: #A13EA180;
$color-gp--purple_o60: #A13EA199;
$color-gp--purple_o70: #A13EA1B3;
$color-gp--purple_o80: #A13EA1CC;
$color-gp--purple_o90: #A13EA1E6;
$color-gp--purple_o100: #A13EA1FF;
$color-gp--purple_o00: #A13EA100;
$color-gp--green: #5CB615;
$color-gp--green_o10: #5CB6151A;
$color-gp--green_o20: #5CB61533;
$color-gp--green_o30: #5CB6154D;
$color-gp--green_o40: #5CB61566;
$color-gp--green_o50: #5CB61580;
$color-gp--green_o60: #5CB61599;
$color-gp--green_o70: #5CB615B3;
$color-gp--green_o80: #5CB615CC;
$color-gp--green_o90: #5CB615E6;
$color-gp--green_o100: #5CB615FF;
$color-gp--green_o00: #5CB61500;
$color-gp--green_dark: #407F0F;
$color-gp--green_dark_o10: #407F0F1A;
$color-gp--green_dark_o20: #407F0F33;
$color-gp--green_dark_o30: #407F0F4D;
$color-gp--green_dark_o40: #407F0F66;
$color-gp--green_dark_o50: #407F0F80;
$color-gp--green_dark_o60: #407F0F99;
$color-gp--green_dark_o70: #407F0FB3;
$color-gp--green_dark_o80: #407F0FCC;
$color-gp--green_dark_o90: #407F0FE6;
$color-gp--green_dark_o100: #407F0FFF;
$color-gp--green_dark_o00: #407F0F00;
$color-gp--green_custo: #209e91;
$color-gp--green_custo_o10: #209e911A;
$color-gp--green_custo_o20: #209e9133;
$color-gp--green_custo_o30: #209e914D;
$color-gp--green_custo_o40: #209e9166;
$color-gp--green_custo_o50: #209e9180;
$color-gp--green_custo_o60: #209e9199;
$color-gp--green_custo_o70: #209e91B3;
$color-gp--green_custo_o80: #209e91CC;
$color-gp--green_custo_o90: #209e91E6;
$color-gp--green_custo_o100: #209e91FF;
$color-gp--green_custo_o00: #209e9100;
$color-gp--orange: #F28D15;
$color-gp--orange_o10: #F28D151A;
$color-gp--orange_o20: #F28D1533;
$color-gp--orange_o30: #F28D154D;
$color-gp--orange_o40: #F28D1566;
$color-gp--orange_o50: #F28D1580;
$color-gp--orange_o60: #F28D1599;
$color-gp--orange_o70: #F28D15B3;
$color-gp--orange_o80: #F28D15CC;
$color-gp--orange_o90: #F28D15E6;
$color-gp--orange_o100: #F28D15FF;
$color-gp--orange_o00: #F28D1500;
$color-gp--red: #FF374A;
$color-gp--red_o10: #FF374A1A;
$color-gp--red_o20: #FF374A33;
$color-gp--red_o30: #FF374A4D;
$color-gp--red_o40: #FF374A66;
$color-gp--red_o50: #FF374A80;
$color-gp--red_o60: #FF374A99;
$color-gp--red_o70: #FF374AB3;
$color-gp--red_o80: #FF374ACC;
$color-gp--red_o90: #FF374AE6;
$color-gp--red_o100: #FF374AFF;
$color-gp--red_o00: #FF374A00;
$color-gp--red_dark: #B22634;
$color-gp--red_dark_o10: #B226341A;
$color-gp--red_dark_o20: #B2263433;
$color-gp--red_dark_o30: #B226344D;
$color-gp--red_dark_o40: #B2263466;
$color-gp--red_dark_o50: #B2263480;
$color-gp--red_dark_o60: #B2263499;
$color-gp--red_dark_o70: #B22634B3;
$color-gp--red_dark_o80: #B22634CC;
$color-gp--red_dark_o90: #B22634E6;
$color-gp--red_dark_o100: #B22634FF;
$color-gp--red_dark_o00: #B2263400;
$color-gp--red_bright: #EF3C3C;
$color-gp--red_bright_o10: #EF3C3C1A;
$color-gp--red_bright_o20: #EF3C3C33;
$color-gp--red_bright_o30: #EF3C3C4D;
$color-gp--red_bright_o40: #EF3C3C66;
$color-gp--red_bright_o50: #EF3C3C80;
$color-gp--red_bright_o60: #EF3C3C99;
$color-gp--red_bright_o70: #EF3C3CB3;
$color-gp--red_bright_o80: #EF3C3CCC;
$color-gp--red_bright_o90: #EF3C3CE6;
$color-gp--red_bright_o100: #EF3C3CFF;
$color-gp--red_bright_o00: #EF3C3C00;
$color-gp--red_1: #ff0303;
$color-gp--red_1_o10: #ff03031A;
$color-gp--red_1_o20: #ff030333;
$color-gp--red_1_o30: #ff03034D;
$color-gp--red_1_o40: #ff030366;
$color-gp--red_1_o50: #ff030380;
$color-gp--red_1_o60: #ff030399;
$color-gp--red_1_o70: #ff0303B3;
$color-gp--red_1_o80: #ff0303CC;
$color-gp--red_1_o90: #ff0303E6;
$color-gp--red_1_o100: #ff0303FF;
$color-gp--red_1_o00: #ff030300;
$color-gp--yellow_prosegur: #FFD102;
$color-gp--yellow_prosegur_o10: #FFD1021A;
$color-gp--yellow_prosegur_o20: #FFD10233;
$color-gp--yellow_prosegur_o30: #FFD1024D;
$color-gp--yellow_prosegur_o40: #FFD10266;
$color-gp--yellow_prosegur_o50: #FFD10280;
$color-gp--yellow_prosegur_o60: #FFD10299;
$color-gp--yellow_prosegur_o70: #FFD102B3;
$color-gp--yellow_prosegur_o80: #FFD102CC;
$color-gp--yellow_prosegur_o90: #FFD102E6;
$color-gp--yellow_prosegur_o100: #FFD102FF;
$color-gp--yellow_prosegur_o00: #FFD10200;
$color-gp--transparent: transparent;
$color-gp--inherit: inherit;
$color-emp--blue: #0066FF;
$color-emp--blue_o10: #0066FF1A;
$color-emp--blue_o20: #0066FF33;
$color-emp--blue_o30: #0066FF4D;
$color-emp--blue_o40: #0066FF66;
$color-emp--blue_o50: #0066FF80;
$color-emp--blue_o60: #0066FF99;
$color-emp--blue_o70: #0066FFB3;
$color-emp--blue_o80: #0066FFCC;
$color-emp--blue_o90: #0066FFE6;
$color-emp--blue_o100: #0066FFFF;
$color-emp--blue_o00: #0066FF00;
$color-emp--blue_bf: #009ff7;
$color-emp--blue_bf_o10: #009ff71A;
$color-emp--blue_bf_o20: #009ff733;
$color-emp--blue_bf_o30: #009ff74D;
$color-emp--blue_bf_o40: #009ff766;
$color-emp--blue_bf_o50: #009ff780;
$color-emp--blue_bf_o60: #009ff799;
$color-emp--blue_bf_o70: #009ff7B3;
$color-emp--blue_bf_o80: #009ff7CC;
$color-emp--blue_bf_o90: #009ff7E6;
$color-emp--blue_bf_o100: #009ff7FF;
$color-emp--blue_bf_o00: #009ff700;
$color-emp--blue_dark: #0B2739;
$color-emp--blue_dark_o10: #0B27391A;
$color-emp--blue_dark_o20: #0B273933;
$color-emp--blue_dark_o30: #0B27394D;
$color-emp--blue_dark_o40: #0B273966;
$color-emp--blue_dark_o50: #0B273980;
$color-emp--blue_dark_o60: #0B273999;
$color-emp--blue_dark_o70: #0B2739B3;
$color-emp--blue_dark_o80: #0B2739CC;
$color-emp--blue_dark_o90: #0B2739E6;
$color-emp--blue_dark_o100: #0B2739FF;
$color-emp--blue_dark_o00: #0B273900;
$color-emp--white: #FFFFFF;
$color-emp--white_o10: #FFFFFF1A;
$color-emp--white_o20: #FFFFFF33;
$color-emp--white_o30: #FFFFFF4D;
$color-emp--white_o40: #FFFFFF66;
$color-emp--white_o50: #FFFFFF80;
$color-emp--white_o60: #FFFFFF99;
$color-emp--white_o70: #FFFFFFB3;
$color-emp--white_o80: #FFFFFFCC;
$color-emp--white_o90: #FFFFFFE6;
$color-emp--white_o100: #FFFFFFFF;
$color-emp--white_o00: #FFFFFF00;
$color-emp--black: #000000;
$color-emp--black_o10: #0000001A;
$color-emp--black_o20: #00000033;
$color-emp--black_o30: #0000004D;
$color-emp--black_o40: #00000066;
$color-emp--black_o50: #00000080;
$color-emp--black_o60: #00000099;
$color-emp--black_o70: #000000B3;
$color-emp--black_o80: #000000CC;
$color-emp--black_o90: #000000E6;
$color-emp--black_o100: #000000FF;
$color-emp--black_o00: #00000000;
$color-emp--grey: #2A2A3C;
$color-emp--grey_o10: #2A2A3C1A;
$color-emp--grey_o20: #2A2A3C33;
$color-emp--grey_o30: #2A2A3C4D;
$color-emp--grey_o40: #2A2A3C66;
$color-emp--grey_o50: #2A2A3C80;
$color-emp--grey_o60: #2A2A3C99;
$color-emp--grey_o70: #2A2A3CB3;
$color-emp--grey_o80: #2A2A3CCC;
$color-emp--grey_o90: #2A2A3CE6;
$color-emp--grey_o100: #2A2A3CFF;
$color-emp--grey_o00: #2A2A3C00;
$color-emp--grey1: #F2F4FF;
$color-emp--grey1_o10: #F2F4FF1A;
$color-emp--grey1_o20: #F2F4FF33;
$color-emp--grey1_o30: #F2F4FF4D;
$color-emp--grey1_o40: #F2F4FF66;
$color-emp--grey1_o50: #F2F4FF80;
$color-emp--grey1_o60: #F2F4FF99;
$color-emp--grey1_o70: #F2F4FFB3;
$color-emp--grey1_o80: #F2F4FFCC;
$color-emp--grey1_o90: #F2F4FFE6;
$color-emp--grey1_o100: #F2F4FFFF;
$color-emp--grey1_o00: #F2F4FF00;
$color-emp--grey2: #D1D5E4;
$color-emp--grey2_o10: #D1D5E41A;
$color-emp--grey2_o20: #D1D5E433;
$color-emp--grey2_o30: #D1D5E44D;
$color-emp--grey2_o40: #D1D5E466;
$color-emp--grey2_o50: #D1D5E480;
$color-emp--grey2_o60: #D1D5E499;
$color-emp--grey2_o70: #D1D5E4B3;
$color-emp--grey2_o80: #D1D5E4CC;
$color-emp--grey2_o90: #D1D5E4E6;
$color-emp--grey2_o100: #D1D5E4FF;
$color-emp--grey2_o00: #D1D5E400;
$color-emp--grey3: #B0B6CA;
$color-emp--grey3_o10: #B0B6CA1A;
$color-emp--grey3_o20: #B0B6CA33;
$color-emp--grey3_o30: #B0B6CA4D;
$color-emp--grey3_o40: #B0B6CA66;
$color-emp--grey3_o50: #B0B6CA80;
$color-emp--grey3_o60: #B0B6CA99;
$color-emp--grey3_o70: #B0B6CAB3;
$color-emp--grey3_o80: #B0B6CACC;
$color-emp--grey3_o90: #B0B6CAE6;
$color-emp--grey3_o100: #B0B6CAFF;
$color-emp--grey3_o00: #B0B6CA00;
$color-emp--grey4: #8F97AF;
$color-emp--grey4_o10: #8F97AF1A;
$color-emp--grey4_o20: #8F97AF33;
$color-emp--grey4_o30: #8F97AF4D;
$color-emp--grey4_o40: #8F97AF66;
$color-emp--grey4_o50: #8F97AF80;
$color-emp--grey4_o60: #8F97AF99;
$color-emp--grey4_o70: #8F97AFB3;
$color-emp--grey4_o80: #8F97AFCC;
$color-emp--grey4_o90: #8F97AFE6;
$color-emp--grey4_o100: #8F97AFFF;
$color-emp--grey4_o00: #8F97AF00;
$color-emp--grey5: #6E7894;
$color-emp--grey5_o10: #6E78941A;
$color-emp--grey5_o20: #6E789433;
$color-emp--grey5_o30: #6E78944D;
$color-emp--grey5_o40: #6E789466;
$color-emp--grey5_o50: #6E789480;
$color-emp--grey5_o60: #6E789499;
$color-emp--grey5_o70: #6E7894B3;
$color-emp--grey5_o80: #6E7894CC;
$color-emp--grey5_o90: #6E7894E6;
$color-emp--grey5_o100: #6E7894FF;
$color-emp--grey5_o00: #6E789400;
$color-emp--grey6: #58617A;
$color-emp--grey6_o10: #58617A1A;
$color-emp--grey6_o20: #58617A33;
$color-emp--grey6_o30: #58617A4D;
$color-emp--grey6_o40: #58617A66;
$color-emp--grey6_o50: #58617A80;
$color-emp--grey6_o60: #58617A99;
$color-emp--grey6_o70: #58617AB3;
$color-emp--grey6_o80: #58617ACC;
$color-emp--grey6_o90: #58617AE6;
$color-emp--grey6_o100: #58617AFF;
$color-emp--grey6_o00: #58617A00;
$color-emp--purple: #A13EA1;
$color-emp--purple_o10: #A13EA11A;
$color-emp--purple_o20: #A13EA133;
$color-emp--purple_o30: #A13EA14D;
$color-emp--purple_o40: #A13EA166;
$color-emp--purple_o50: #A13EA180;
$color-emp--purple_o60: #A13EA199;
$color-emp--purple_o70: #A13EA1B3;
$color-emp--purple_o80: #A13EA1CC;
$color-emp--purple_o90: #A13EA1E6;
$color-emp--purple_o100: #A13EA1FF;
$color-emp--purple_o00: #A13EA100;
$color-emp--green: #00B981;
$color-emp--green_o10: #00B9811A;
$color-emp--green_o20: #00B98133;
$color-emp--green_o30: #00B9814D;
$color-emp--green_o40: #00B98166;
$color-emp--green_o50: #00B98180;
$color-emp--green_o60: #00B98199;
$color-emp--green_o70: #00B981B3;
$color-emp--green_o80: #00B981CC;
$color-emp--green_o90: #00B981E6;
$color-emp--green_o100: #00B981FF;
$color-emp--green_o00: #00B98100;
$color-emp--green_custo: #209e91;
$color-emp--green_custo_o10: #209e911A;
$color-emp--green_custo_o20: #209e9133;
$color-emp--green_custo_o30: #209e914D;
$color-emp--green_custo_o40: #209e9166;
$color-emp--green_custo_o50: #209e9180;
$color-emp--green_custo_o60: #209e9199;
$color-emp--green_custo_o70: #209e91B3;
$color-emp--green_custo_o80: #209e91CC;
$color-emp--green_custo_o90: #209e91E6;
$color-emp--green_custo_o100: #209e91FF;
$color-emp--green_custo_o00: #209e9100;
$color-emp--orange: #EC624B;
$color-emp--orange_o10: #EC624B1A;
$color-emp--orange_o20: #EC624B33;
$color-emp--orange_o30: #EC624B4D;
$color-emp--orange_o40: #EC624B66;
$color-emp--orange_o50: #EC624B80;
$color-emp--orange_o60: #EC624B99;
$color-emp--orange_o70: #EC624BB3;
$color-emp--orange_o80: #EC624BCC;
$color-emp--orange_o90: #EC624BE6;
$color-emp--orange_o100: #EC624BFF;
$color-emp--orange_o00: #EC624B00;
$color-emp--red: #D23C31;
$color-emp--red_o10: #D23C311A;
$color-emp--red_o20: #D23C3133;
$color-emp--red_o30: #D23C314D;
$color-emp--red_o40: #D23C3166;
$color-emp--red_o50: #D23C3180;
$color-emp--red_o60: #D23C3199;
$color-emp--red_o70: #D23C31B3;
$color-emp--red_o80: #D23C31CC;
$color-emp--red_o90: #D23C31E6;
$color-emp--red_o100: #D23C31FF;
$color-emp--red_o00: #D23C3100;
$color-emp--red_dark: #B22634;
$color-emp--red_dark_o10: #B226341A;
$color-emp--red_dark_o20: #B2263433;
$color-emp--red_dark_o30: #B226344D;
$color-emp--red_dark_o40: #B2263466;
$color-emp--red_dark_o50: #B2263480;
$color-emp--red_dark_o60: #B2263499;
$color-emp--red_dark_o70: #B22634B3;
$color-emp--red_dark_o80: #B22634CC;
$color-emp--red_dark_o90: #B22634E6;
$color-emp--red_dark_o100: #B22634FF;
$color-emp--red_dark_o00: #B2263400;
$color-emp--red_bright: #EF3C3C;
$color-emp--red_bright_o10: #EF3C3C1A;
$color-emp--red_bright_o20: #EF3C3C33;
$color-emp--red_bright_o30: #EF3C3C4D;
$color-emp--red_bright_o40: #EF3C3C66;
$color-emp--red_bright_o50: #EF3C3C80;
$color-emp--red_bright_o60: #EF3C3C99;
$color-emp--red_bright_o70: #EF3C3CB3;
$color-emp--red_bright_o80: #EF3C3CCC;
$color-emp--red_bright_o90: #EF3C3CE6;
$color-emp--red_bright_o100: #EF3C3CFF;
$color-emp--red_bright_o00: #EF3C3C00;
$color-emp--red_1: #ff0303;
$color-emp--red_1_o10: #ff03031A;
$color-emp--red_1_o20: #ff030333;
$color-emp--red_1_o30: #ff03034D;
$color-emp--red_1_o40: #ff030366;
$color-emp--red_1_o50: #ff030380;
$color-emp--red_1_o60: #ff030399;
$color-emp--red_1_o70: #ff0303B3;
$color-emp--red_1_o80: #ff0303CC;
$color-emp--red_1_o90: #ff0303E6;
$color-emp--red_1_o100: #ff0303FF;
$color-emp--red_1_o00: #ff030300;
$color-emp--transparent: transparent;
$color-emp--inherit: inherit;
$color-plus--yellow_plus: #F2B820;
$color-plus--yellow_plus_o10: #F2B8201A;
$color-plus--yellow_plus_o20: #F2B82033;
$color-plus--yellow_plus_o30: #F2B8204D;
$color-plus--yellow_plus_o40: #F2B82066;
$color-plus--yellow_plus_o50: #F2B82080;
$color-plus--yellow_plus_o60: #F2B82099;
$color-plus--yellow_plus_o70: #F2B820B3;
$color-plus--yellow_plus_o80: #F2B820CC;
$color-plus--yellow_plus_o90: #F2B820E6;
$color-plus--yellow_plus_o100: #F2B820FF;
$color-plus--yellow_plus_o00: #F2B82000;
$color-plus--green_plus: #A9CF38;
$color-plus--green_plus_o10: #A9CF381A;
$color-plus--green_plus_o20: #A9CF3833;
$color-plus--green_plus_o30: #A9CF384D;
$color-plus--green_plus_o40: #A9CF3866;
$color-plus--green_plus_o50: #A9CF3880;
$color-plus--green_plus_o60: #A9CF3899;
$color-plus--green_plus_o70: #A9CF38B3;
$color-plus--green_plus_o80: #A9CF38CC;
$color-plus--green_plus_o90: #A9CF38E6;
$color-plus--green_plus_o100: #A9CF38FF;
$color-plus--green_plus_o00: #A9CF3800;
$color-plus--purple_plus: #7350CD;
$color-plus--purple_plus_o10: #7350CD1A;
$color-plus--purple_plus_o20: #7350CD33;
$color-plus--purple_plus_o30: #7350CD4D;
$color-plus--purple_plus_o40: #7350CD66;
$color-plus--purple_plus_o50: #7350CD80;
$color-plus--purple_plus_o60: #7350CD99;
$color-plus--purple_plus_o70: #7350CDB3;
$color-plus--purple_plus_o80: #7350CDCC;
$color-plus--purple_plus_o90: #7350CDE6;
$color-plus--purple_plus_o100: #7350CDFF;
$color-plus--purple_plus_o00: #7350CD00;
$color-plus--pink_plus: #F03C87;
$color-plus--pink_plus_o10: #F03C871A;
$color-plus--pink_plus_o20: #F03C8733;
$color-plus--pink_plus_o30: #F03C874D;
$color-plus--pink_plus_o40: #F03C8766;
$color-plus--pink_plus_o50: #F03C8780;
$color-plus--pink_plus_o60: #F03C8799;
$color-plus--pink_plus_o70: #F03C87B3;
$color-plus--pink_plus_o80: #F03C87CC;
$color-plus--pink_plus_o90: #F03C87E6;
$color-plus--pink_plus_o100: #F03C87FF;
$color-plus--pink_plus_o00: #F03C8700;
$color-plus--transparent: transparent;
$color-plus--inherit: inherit;
