@import "styles/colors.scss";

.cms-label {
  width: fit-content;
  height: 22px;
  &.square {
    border-radius: 0px;
    padding: 2px 4px;
    &.promocion {
      background-color: $color-gp--red_dark;
    }
    &.promocion-inverse {
      background-color: $color-gp--white;
    }
    &.informacion {
      background-color: $color-gp--blue_dark;
    }
    &.informacion-inverse {
      background-color: $color-gp--white;
    }
    &.active {
      background-color: $color-gp--blue_o10;
    }
    &.active-inverse {
      background-color: $color-gp--white;
    }
    &.inactive {
      background-color: $color-gp--grey1;
    }
    &.inactive-inverse {
      background-color: $color-gp--white;
    }
    &.success {
      background-color: $color-gp--green_o10;
    }
    &.success-inverse {
      background-color: $color-gp--white;
    }
    &.warning {
      background-color: $color-gp--orange_o10;
    }
    &.warning-inverse {
      background-color: $color-gp--white;
    }
    &.error {
      background-color: $color-gp--red_o10;
    }
    &.error-inverse {
      background-color: $color-gp--white;
    }
  }
  &.tears {
    border-radius: 16px 0px;
    padding: 2px 16px;
    &.informacion {
      background-color: $color-gp--blue_dark;
    }
  }
  &.roof {
    border-radius: 16px 16px 0px 0px;
    padding: 2px 16px;
    width: 100%;
    &.recomendado {
      background-color: $color-gp--blue_dark;
    }
    &.contratado {
      background-color: $color-gp--green_o10;
    }
  }
}
