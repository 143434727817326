.tv-filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
  &--title {
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--row {
    display: flex;
    align-items: center;
    margin: 0.25rem 0rem;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
    &_button {
      margin-right: 0.3rem;
      margin-left: 0.2rem;
      .button-type--collapsed {
        border: solid 1px #dddddd !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
      &--title {
        flex-direction: column;
        gap: 1rem;
      }
      &--button-configura {
        width: 100%;
      }
      &--row {
        &_button {
          .buttonx {
            &.width--short {
              min-width: 128px;
            }
          }
        }
      }
  }
}
